import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserService } from '@app/shared/services';
import { UserAcceptedObservable } from '@app/shared/services/user-accepted-observable.service';
@Component({
  selector: 'app-splash-page',
  templateUrl: 'splash-page.component.html',
  styleUrls: ['splash-page.component.scss'],
})
export class SplashPageComponent implements OnInit {
  userAccepted: boolean = false;
  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private userAccept: UserAcceptedObservable
  ) {}

  ngOnInit(): void {
    this.auth.authenticated$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) this.router.navigate(['/login']);
    });
    this.userAccepted = JSON.parse(
      localStorage.getItem('userAccepted') as string
    );
    if (this.userAccepted) this.router.navigate(['/']);
    else this.userAccept.notAccepted();
  }

  accept() {
    this.userAccepted = true;
    this.userAccept.accepted();
    localStorage.setItem('userAccepted', JSON.stringify(this.userAccepted));
    localStorage.setItem('userAcceptedDate', JSON.stringify(Date.now()));
    this.auth.redirectFromLogin();
    this.auth.showLoginInfo();
  }

  decline() {
    this.auth.logout();
  }
}
