import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'farReferenceNum',
})
export class FarReferenceNumPipe implements PipeTransform {
  transform(farId: string | number): string {
    let refNumber = farId.toString();
    return refNumber.slice(refNumber.length - 7);
  }
}
