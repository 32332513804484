<div class="my-2">
  <mat-table
    [dataSource]="groups"
    aria-label="'groups-table"
    #groupTable
    multiTemplateDataRows
    matSort
    [matSortActive]="
      filterParams.sort && filterParams.sort.active
        ? filterParams.sort.active
        : ''
    "
    [matSortDirection]="
      filterParams.sort && filterParams.sort.direction
        ? filterParams.sort.direction
        : ''
    "
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="GroupSortField.NAME">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!groupColumnsDisplay[GroupSortField.NAME]"
      >
        {{ GroupSortLabels[GroupSortField.NAME] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let group"
        [hidden]="!groupColumnsDisplay[GroupSortField.NAME]"
      >
        <div [matTooltip]="group.name" style="line-break: anywhere">
          {{ group.name | default }}
        </div>
        <span>
          <mat-icon
            matTooltip="Read Only Record"
            class="ml-2"
            style="opacity: 0.7"
            color="primary"
            *ngIf="!group.isEditable"
            svgIcon="pencil-off-outline"
          >
          </mat-icon>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="GroupSortField.DESCRIPTION">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!groupColumnsDisplay[GroupSortField.DESCRIPTION]"
      >
        {{ GroupSortLabels[GroupSortField.DESCRIPTION] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let group"
        [hidden]="!groupColumnsDisplay[GroupSortField.DESCRIPTION]"
      >
        {{ group.description | default }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="GroupSortField.CREATED_DATE">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!groupColumnsDisplay[GroupSortField.CREATED_DATE]"
      >
        {{ GroupSortLabels[GroupSortField.CREATED_DATE] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let group"
        [hidden]="!groupColumnsDisplay[GroupSortField.CREATED_DATE]"
      >
        {{ group.createdDate | date: "MMM d, y, HH:mm:ss" }}
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="GroupSortField.FN_COUNT">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!groupColumnsDisplay[GroupSortField.FN_COUNT]"
      >
        {{ GroupSortLabels[GroupSortField.FN_COUNT] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center white-arrow"
        *matCellDef="let group"
        [hidden]="!groupColumnsDisplay[GroupSortField.FN_COUNT]"
      >
        <span *ngIf="!group.foreignVisitorCount">0</span>
        <button
          mat-button
          color="primary"
          *ngIf="group.foreignVisitorCount > 0 && canViewFN"
          (click)="
            navigateToFNs(
              group.foreignVisitorCount,
              '../fvs',
              'foreignVisitorGroupId',
              group.id
            )
          "
        >
          {{ group.foreignVisitorCount }}
        </button>
        <span *ngIf="group.foreignVisitorCount > 0 && !canViewFN">{{
          group.foreignVisitorCount
        }}</span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="GroupSortField.TYPE">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        mat-sort-header
        [hidden]="!groupColumnsDisplay[GroupSortField.TYPE]"
      >
        {{ GroupSortLabels[GroupSortField.TYPE] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let group"
        [hidden]="!groupColumnsDisplay[GroupSortField.TYPE]"
      >
        <mat-icon
          [matTooltip]="
            group.foreignVisitorGroupType === 'excel_import'
              ? 'Excel Import'
              : 'Manual Link'
          "
          [style]="
            group.foreignVisitorGroupType === 'excel_import'
              ? 'color: rgb(36, 189, 115)'
              : ''
          "
          [svgIcon]="
            group.foreignVisitorGroupType === 'excel_import'
              ? 'microsoft-excel'
              : 'link-box-outline'
          "
        ></mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="GroupSortField.NETWORK">
      <mat-header-cell
        class="justify-content-center white-arrow"
        *matHeaderCellDef
        [hidden]="!groupColumnsDisplay[GroupSortField.NETWORK]"
      >
        {{ GroupSortLabels[GroupSortField.NETWORK] }}
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let group"
        [hidden]="!groupColumnsDisplay[GroupSortField.NETWORK]"
      >
        <mat-icon
          [svgIcon]="'alpha-' + group.networkDomain?.toLowerCase() + '-box'"
          matTooltip="Network"
          aria-hidden="false"
          color="primary"
        ></mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="!groupColumnsDisplay['actions']"
      >
        Actions
      </mat-header-cell>
      <mat-cell
        class="justify-content-center"
        *matCellDef="let group"
        [hidden]="!groupColumnsDisplay['actions']"
      >
        <button
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
        >
          <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            [appGroupEdit]="group.id"
            (appGroupEdited)="onEdited($event)"
            id="view-button"
          >
            <ng-container
              *ngIf="
                !group.isEditable ||
                group.foreignVisitorGroupType === 'excel_import'
              "
            >
              <mat-icon>visibility</mat-icon> View
            </ng-container>
            <ng-container
              *ngIf="
                group.isEditable &&
                group.foreignVisitorGroupType !== 'excel_import'
              "
            >
              <mat-icon svgIcon="square-edit-outline"></mat-icon> Edit
            </ng-container>
          </button>
          <button
            *ngIf="
              group.isEditable &&
              group.foreignVisitorGroupType !== 'excel_import'
            "
            mat-menu-item
            (click)="addFnToGroup(group)"
            id="add-fn-group-button"
          >
            <mat-icon svgIcon="plus-circle-outline"></mat-icon> Add FN
          </button>
          <ng-container *appIfRoles="FVRescreenRoles">
            <span *ngIf="group.isEditable">
              <button
                mat-menu-item
                [disabled]="!group.isRescreenable"
                color="primary"
                [matTooltip]="
                  group.isRescreenable
                    ? 'Rescreen All Eligible Foreign Nationals'
                    : 'Rescreen Unavailable - No Eligible Foreign Nationals'
                "
                (click)="openConfirmationDialog(group.id)"
              >
                <div class="d-flex align-items-center gap-1">
                  <mat-icon svgIcon="send-circle-outline"></mat-icon>
                  <span>Rescreen</span>
                </div>
              </button>
            </span>
          </ng-container>
          <button
            *ngIf="
              group.isEditable &&
              group.foreignVisitorGroupType !== 'excel_import'
            "
            mat-menu-item
            (click)="deleteGroup(group.id)"
            id="delete-button"
          >
            <mat-icon>delete</mat-icon> Delete
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="groupColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: groupColumns"></mat-row>
  </mat-table>
</div>
