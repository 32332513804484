import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterParams, User, Role, AccountStatus } from '@app/shared/models';
import { Sort } from '@angular/material/sort';
import {
  AlertService,
  AppConfigValue,
  UserService,
  UserSortField,
  UserSortFieldLabels,
} from '@app/shared/services';
import { AuthService } from '@app/auth/auth.service';
import { AccountPwdResendDialogComponent } from '../account-pwd-resend-dialog/account-pwd-resend-dialog.component';
import { ColumnChooserComponent } from '@app/shared/components';
import { ColumnChooserEventObservable } from '@app/shared/components/column-chooser/column-chooser-observable.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export const UserTableColumns: string[] = [
  UserSortField.SURNAME,
  UserSortField.EMAIL,
  UserSortField.PHONE,
  UserSortField.ORGANIZATION,
  UserSortField.STATUS,
  UserSortField.LAST_LOGIN,
  UserSortField.ROLES,
  'actions',
];

export const UserTableName = 'userTable';

@Component({
  selector: 'app-account-user-table',
  templateUrl: './account-user-table.component.html',
  styleUrls: ['./account-user-table.component.scss'],
})
export class AccountUserTableComponent implements OnInit, OnDestroy {
  position = 'right';
  Role = Role;
  AccountStatus = AccountStatus;

  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;

  @Input() users: User[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  private ngUnsubscribe = new Subject<void>();

  UserSortField = UserSortField;
  UserSortFieldLabels = UserSortFieldLabels;

  userColumns = UserTableColumns;

  userColumnsDisplay: { [key: string]: boolean } = this.userColumns.reduce(
    (val, col) => ({ [col]: true, ...val }),
    {} as { [key: string]: boolean }
  );

  currentUser: User | null;

  constructor(
    public dialog: MatDialog,
    private alert: AlertService,
    private userService: UserService,
    private auth: AuthService,
    private _ColumnChooserEventObservable: ColumnChooserEventObservable
  ) {
    this.currentUser = this.auth.getUser();
  }

  ngOnInit(): void {
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      if (event.tableName === UserTableName) {
        this.userColumnsDisplay = ColumnChooserComponent.updateTableDisplay(
          event,
          this.userColumns,
          this.userColumnsDisplay
        );
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  resendPassword(user: User) {
    this.alert
      .confirm({
        title: `Resend Temporary Password`,
        message: `Would you like to resend ${user.givenName} ${user.surname} a temporary password?`,
        performAction: () => {
          if (user.id) return this.userService.resendPassword(user.id, user);
        },
        successMsg: `${user.givenName} ${user.surname}'s temporary password has been resent!`,
        confirmText: 'Send',
      })
      .subscribe();
  }

  resetPasswordConfirmation(user: User) {
    this.alert
      .confirm({
        title: `Reset User's Password`,
        message: `${user.givenName} ${user.surname}'s password will automatically be reset.  Do you want to continue?`,
        performAction: () => this.userService.resetPassword(user.id!, false),
        confirmText: 'Reset',
      })
      .subscribe((result) => {
        this.dialog.open(AccountPwdResendDialogComponent, {
          data: { user: user, password: result['value'] },
          width: '600px',
        });
      });
  }

  checkPriv(targetRoles: Role[], currentUserRoles: Role[] = []) {
    if (
      targetRoles?.includes(Role.sv_admin) &&
      !currentUserRoles.includes(Role.sv_admin)
    ) {
      return false;
    } else return true;
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }
}
