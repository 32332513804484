import { Pipe, PipeTransform } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { FvWork } from '@app/shared/models/fv-work.model';

@Pipe({
  name: 'currentWork',
})
export class CurrentWorkPipe implements PipeTransform {
  transform(fv: ForeignVisitor): FvWork | null {
    //assume item with latest created date is the current work
    if (fv.employment && fv.employment.length > 0) {
      let works: FvWork[] | null = fv.employment.slice().sort((a, b) => {
        if (a.createdDate && b.createdDate) {
          const dateA = new Date(a.createdDate).valueOf();
          const dateB = new Date(b.createdDate).valueOf();
          return (dateA - dateB) * -1;
        } else {
          return 0;
        }
      });
      return works[0];
    } else {
      return null;
    }
  }
}
