import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  ForeignVisitor,
  FvAliasAssociation,
  Passport,
  Visa,
} from '@app/shared/models';
import { take } from 'rxjs/operators';

@Injectable()
export class FvCreationService {
  public obsAlias = new BehaviorSubject<FvAliasAssociation[]>([]);
  shareAlias: Observable<FvAliasAssociation[]> = this.obsAlias.asObservable();

  public obsPassports = new BehaviorSubject<Passport[]>([]);
  public obsVisas = new BehaviorSubject<Visa[]>([]);
  public obsPassportVisaMap = new BehaviorSubject<any[]>([]);
  private _obsFV = new BehaviorSubject<ForeignVisitor | null>(null);
  public obsFV$ = this._obsFV.asObservable();

  updateFV(newFV: ForeignVisitor) {
    this._obsFV.next(newFV);
  }

  public clearPassportVisaMap(): void {
    this.obsPassportVisaMap.next([]);
  }

  public clearShareAlias(): void {
    this.obsAlias.next([]);
  }

  public addShareAlias(item: FvAliasAssociation) {
    this.shareAlias.pipe(take(1)).subscribe((val) => {
      const newArr = [...val, item];
      this.obsAlias.next(newArr);
    });
  }

  public removeShareAlias(idx: number) {
    this.shareAlias.pipe(take(1)).subscribe((_val) => {
      const arr = this.obsAlias.getValue();
      arr.splice(idx, 1);
      this.obsAlias.next(arr);
    });
  }

  public clearPassports(): void {
    this.obsPassports.next([]);
  }

  public clearVisas(): void {
    this.obsVisas.next([]);
  }

  //remove any visas orphaned by passport removal from passport-visa map
  public removeOrphanVisas(passport: Passport) {
    let updatedMap: any[] = [];

    for (let entry of this.obsPassportVisaMap.value) {
      if (entry.passportNum != passport.number) {
        updatedMap.push(entry);
      }
    }
    this.obsPassportVisaMap.next(updatedMap);
  }
}
