<div class="grid-collection">
  <div *ngIf="dashboardType !== 'baseline'">
    <app-search-toolbar
      #searchToolbar
      [filterFields]="filterFields"
      [sortFields]="FvSorts"
      [filterParams]="filterParams"
      [columns]="FVTableColumns"
      [columnLabels]="FvSortFieldLabels"
      [tableName]="FVTableName"
      [compactCard]="compactCard"
      [actionButtons]="headerActionButtons"
      (filterParamsChange)="onFilterParamChange($event)"
      [(view)]="view"
      [loading]="loading"
      (groupSelectChange)="groupModeChange($event)"
    >
      Foreign Nationals
    </app-search-toolbar>
  </div>
  <ng-container *ngIf="pageableCollection">
    <ng-container
      *ngIf="
        pageableCollection.content && pageableCollection.content.length;
        else noData
      "
    >
      <div class="grid-main">
        <app-fv-table
          *ngIf="view === 'table'; else fnCards"
          [fvs]="pageableCollection.content"
          [farScreeningEligibility]="farScreeningEligibility"
          [groupSelectionMode]="groupSelectionMode"
          [filterParams]="filterParams"
          (filterParamsChange)="onFilterParamChange($event)"
        ></app-fv-table>
        <ng-template #fnCards>
          <div class="my-3 d-flex flex-wrap gap justify-content-center">
            <ng-container *ngFor="let fn of pageableCollection.content">
              <app-fn-card
                [fn]="fn"
                [groupSelectionMode]="groupSelectionMode"
                [farScreeningEligibility]="farScreeningEligibility"
              ></app-fn-card>
            </ng-container>
          </div>
        </ng-template>
        <ng-template #cards>
          <ng-container
            *ngIf="compactCard; then horizontalCard; else card"
          ></ng-container>
          <ng-template #card>
            <div class="row">
              <div
                class="col-auto my-2 px-2"
                *ngFor="let fv of pageableCollection.content"
              >
                <app-fn-card
                  [fn]="fv"
                  [farScreeningEligibility]="farScreeningEligibility"
                ></app-fn-card>
              </div>
            </div>
          </ng-template>
          <ng-template #horizontalCard>
            <mat-list style="line-height: normal" class="flex-fill p-0">
              <mat-list-item
                style="border-bottom: 1px solid lightgrey; height: auto"
                *ngFor="let item of pageableCollection.content; let i = index"
              >
                <app-avatar
                  matListItemAvatar
                  [size]="'small'"
                  [fv]="item"
                ></app-avatar>
                <h1 matListItemTitle class="d-flex mt-1">
                  {{ item | fullname: "surnameFirst" }}
                </h1>
                <div matListItemLine class="mt-2">
                  <span *ngIf="item.dateOfBirth as dateOfBirth">
                    {{ item.dateOfBirth | age }}</span
                  >
                  <span *ngIf="!item.dateOfBirth"> N/A</span>
                  /
                  {{ item.gender | titlecase | default }}
                </div>
                <div matListItemLine class="mb-2">
                  Passport: {{ (item | currentPassport)?.number | default }}
                </div>
                <app-screening-indicator
                  style="margin-top: 0.75rem"
                  matListItemMeta
                  *ngIf="item.latestScreening as screening"
                  [id]="'screening-indicator-' + item.id"
                  [screening]="screening"
                  [display]="
                    screening.result && screening.status !== 'Error'
                      ? 'result'
                      : 'status'
                  "
                ></app-screening-indicator>
                <button
                  style="margin-top: 0.75rem"
                  matListItemMeta
                  mat-icon-button
                  [id]="'far-fv-view-' + i"
                  matTooltip="Go To Foreign National's Profile"
                  [routerLink]="['/fv', item.id]"
                >
                  <mat-icon class="mat-24"> open_in_new </mat-icon>
                </button>
              </mat-list-item>
            </mat-list>
          </ng-template>
        </ng-template>
        <app-paginator
          *ngIf="pageableCollection"
          [filterParams]="filterParams"
          (filterParamsChange)="onFilterParamChange($event)"
          [pageIndex]="pageableCollection.number"
          [pageSize]="pageableCollection.size"
          [totalElements]="pageableCollection.totalElements"
          [groupSelectionMode]="groupSelectionMode || false"
        ></app-paginator>
      </div> </ng-container
  ></ng-container>
  <ng-template #noData>
    <app-no-result-found class="no-results"></app-no-result-found>
  </ng-template>
</div>
