import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { OrgLocation } from '@app/shared/models';
import { Crud, CrudService } from '@shared/services/crud.service';
import { AddressService } from './address.service';
import { SortSelect } from '@shared/components';
import { Observable } from 'rxjs';
import { pick, without } from 'lodash';
import { switchMap } from 'rxjs/operators';

export enum OrgLocationSortField {
  NAME = 'name',
  ADDRESS = 'address.line1',
  CITY = 'address.city',
  STATE_PROVINCE = 'address.stateProvince',
  CITY_STATE_PROVINCE = 'address.cityStateProvince',
  POSTAL_CODE = 'address.postalCode',
  COUNTRY = 'address.countryCode',
  ORG = 'organization.shortName',
  FAR_COUNT = 'farcount',
}

export const OrgLocationSortLabels: { [key in OrgLocationSortField]: string } =
  {
    [OrgLocationSortField.NAME]: 'Name',
    [OrgLocationSortField.ADDRESS]: 'Street Address',
    [OrgLocationSortField.CITY]: 'City',
    [OrgLocationSortField.STATE_PROVINCE]: 'State/Province',
    [OrgLocationSortField.CITY_STATE_PROVINCE]: 'City/State/Province',
    [OrgLocationSortField.POSTAL_CODE]: 'Zip Code',
    [OrgLocationSortField.COUNTRY]: 'Country',
    [OrgLocationSortField.ORG]: 'Organization',
    [OrgLocationSortField.FAR_COUNT]: 'FAR Count',
  };

export const OrgLocationSorts: SortSelect[] = without(
  Object.values(OrgLocationSortField),
  // Non-sortable fields
  OrgLocationSortField.CITY_STATE_PROVINCE,
  OrgLocationSortField.FAR_COUNT
).map((value) => {
  return { label: OrgLocationSortLabels[value], value };
});

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/organizationLocations`,
  hasIdPathUpdate: false,
  entity: 'Location',
})
export class OrgLocationService extends CrudService<OrgLocation> {
  constructor(http: HttpClient, public addressService: AddressService) {
    super(http);
  }

  /**
   * Pre-existing locations require their address be saved separately, then the
   * location be saved. This picks out the location, calls the Address Service
   * then saves the location. If for some reason there is no address, the location
   * is then saved via the Crud Service like normal.
   *
   * @param location OrgLocation - Location that's being saved
   * @returns a new OrgLocation, potentially with an address
   */
  saveWithAddress(location: OrgLocation): Observable<OrgLocation> {
    const newAddress = pick(location, 'address').address;
    return newAddress
      ? this.addressService.save(newAddress).pipe(
          switchMap((address) => {
            const assocLocation: OrgLocation = {
              ...location,
              address: { ...address },
            };
            return super.save(assocLocation);
          })
        )
      : super.save(location);
  }
}
