import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AddressPipe,
  AgePipe,
  AuditOrgPipe,
  AuditUserPipe,
  CommunicationCodeDescriptionPipe,
  CountryPipe,
  CurrentPassportPipe,
  CurrentScreeningPipe,
  CurrentVisaPipe,
  CurrentWorkPipe,
  DaysAgoPipe,
  DaysUntilPipe,
  DefaultPipe,
  DerogDescriptionPipe,
  FarLocationPipe,
  FarReferenceNumPipe,
  FullnamePipe,
  GetNumFvsPipe,
  GetOrgNamePipe,
  IsBlankPipe,
  IsDateBeforePipe,
  MilitaryDatePipe,
  MilitaryDateTimePipe,
  NationalIdPipe,
  OffenseCodeDescriptionPipe,
  PhoneNumberPipe,
  RelationalCodeDescriptionPipe,
  RolePipe,
  ScreeningClassPipe,
  ScreeningColorPipe,
  ScreeningDatePipe,
  ScreeningIconPipe,
  ScreeningLabelPipe,
  SecureImagePipe,
  SortEmailPipe,
  SortPhoneNumbersPipe,
  StatusMapperPipe,
  TimeframeCodeDescriptionPipe,
  UserRoleListPipe,
  VerificationCodeDescriptionPipe,
  WordifyPipe,
} from '@shared/pipes';

const SHARED_PIPES = [
  AddressPipe,
  AgePipe,
  AuditOrgPipe,
  AuditUserPipe,
  CommunicationCodeDescriptionPipe,
  CountryPipe,
  CurrentPassportPipe,
  CurrentScreeningPipe,
  CurrentVisaPipe,
  CurrentWorkPipe,
  DaysAgoPipe,
  DaysUntilPipe,
  DefaultPipe,
  DerogDescriptionPipe,
  FarLocationPipe,
  FarReferenceNumPipe,
  FullnamePipe,
  GetNumFvsPipe,
  GetOrgNamePipe,
  IsBlankPipe,
  IsDateBeforePipe,
  MilitaryDatePipe,
  MilitaryDateTimePipe,
  NationalIdPipe,
  OffenseCodeDescriptionPipe,
  PhoneNumberPipe,
  RelationalCodeDescriptionPipe,
  RolePipe,
  ScreeningClassPipe,
  ScreeningColorPipe,
  ScreeningDatePipe,
  ScreeningIconPipe,
  ScreeningLabelPipe,
  SecureImagePipe,
  SortEmailPipe,
  SortPhoneNumbersPipe,
  StatusMapperPipe,
  TimeframeCodeDescriptionPipe,
  UserRoleListPipe,
  VerificationCodeDescriptionPipe,
  WordifyPipe,
];

@NgModule({
  declarations: SHARED_PIPES,
  imports: [CommonModule],
  exports: SHARED_PIPES,
})
export class PipesModule {}
