import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { FarSortField, FarSortFieldLabels } from '@app/shared/services';
import { takeUntil } from 'rxjs/operators';
import { ColumnChooserComponent } from '@app/shared/components';
import { ColumnChooserEventObservable } from '@app/shared/components/column-chooser/column-chooser-observable.service';
import { Subject } from 'rxjs';
import {
  FAR,
  FilterParams,
  Role,
  ScreeningDateFormat,
  Sponsor,
} from '@app/shared/models';
import { AuthService } from '@app/auth/auth.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const FARTableColumns: string[] = [
  FarSortField.ORG,
  FarSortField.EVENT_TITLE,
  FarSortField.PURPOSE_TYPE,
  FarSortField.CREATED_DATE,
  FarSortField.SPONSOR_SURNAME,
  FarSortField.VISITS,
  FarSortField.ASSOC_FV_STATUS,
  'additionalData',
  'actions',
];

export const FARTableName = 'farTable';

@Component({
  selector: 'app-far-table',
  templateUrl: './far-table.component.html',
  styleUrls: ['./far-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', padding: '5px 0' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class FarTableComponent implements OnInit, OnDestroy {
  @Input() fars: FAR[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  Role = Role;
  private ngUnsubscribe = new Subject<void>();
  expandedFar: FAR | null;

  FarSortField = FarSortField;
  FarSortFieldLabel = FarSortFieldLabels;
  ScreeningDateFormat = ScreeningDateFormat;

  /**
   * NOTE: 6-16-2022 - Removed Name and Address sort fields.
  bc of the nesting, the sort seems to be buggy, adding multiple items for the
  table depending on what data is referenced with the sort.
   *  */
  dataColumns: string[] = FARTableColumns;

  farColumnsDisplay: { [key: string]: boolean } = this.dataColumns.reduce(
    (val, col) => ({ [col]: true, ...val }),
    {} as { [key: string]: boolean }
  );

  constructor(
    private router: Router,
    private authService: AuthService,
    private _ColumnChooserEventObservable: ColumnChooserEventObservable,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      if (event.tableName === FARTableName) {
        this.farColumnsDisplay = ColumnChooserComponent.updateTableDisplay(
          event,
          this.dataColumns,
          this.farColumnsDisplay
        );
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  editRow(id: string) {
    this.router.navigateByUrl(`far/${id}/edit`);
  }
  viewRow(id: string) {
    this.router.navigateByUrl(`far/${id}`);
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  checkMultiNoDate(farLocations: any[]) {
    if (farLocations.length > 1) return false;
    else if (!farLocations[0].startDate && !farLocations[0].endDate)
      return true;
    return false;
  }

  getMarginClass(item: any): string {
    if (!this.isCityState(item)) {
      return 'mb-0';
    }
    return '';
  }

  isCityState(item: any): boolean {
    if (item.organizationLocation && item.organizationLocation.address) {
      if (
        !item.organizationLocation.address.city &&
        !item.organizationLocation.address.stateProvince
      ) {
        return false;
      }
    }
    return true;
  }

  showSponsor(sponsor: Sponsor): string {
    let result: string = '';
    let hasName: boolean | undefined =
      sponsor.surname || sponsor.givenName ? true : false;
    let hasBothNames: boolean | undefined =
      sponsor.surname && sponsor.givenName ? true : false;
    if (hasName) {
      result = sponsor.surname ? result + sponsor.surname : '';
      result = hasBothNames ? result + ', ' : '';
      result = sponsor.givenName ? result + sponsor.givenName : '';
    }
    if (!hasName) {
      result += 'N/A';
    }
    return result;
  }

  toggleRow(far: FAR) {
    far.foreignAccessRequestLocations &&
    far.foreignAccessRequestLocations.length > 0
      ? (this.expandedFar = this.expandedFar === far ? null : far)
      : null;
    this.cd.detectChanges();
  }
}
