import { Pipe, PipeTransform } from '@angular/core';
import { NationalId } from '../models';

@Pipe({
  name: 'nationalId',
})
export class NationalIdPipe implements PipeTransform {
  transform(component: NationalId[]): string {
    if (
      component === undefined ||
      component === null ||
      component.length === 0 ||
      component[0].issuingCountryCode === undefined
    ) {
      return 'N/A';
    } else if (component.length === 1) {
      return component[0].issuingCountryCode + ': ' + component[0].nationalId;
    } else {
      return component.length + ' entries';
    }
  }
}
