import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty, isNumber, isBoolean, isObject, omitBy } from 'lodash';

export function isBlank(value: any): boolean {
  if (isNumber(value) || isBoolean(value)) {
    return false;
  }

  if (isObject(value)) {
    const objCheck = omitBy(value, isBlank);
    return isEmpty(objCheck);
  }

  return isEmpty(value);
}

@Pipe({
  name: 'IsBlank',
})
export class IsBlankPipe implements PipeTransform {
  transform(value: any): boolean {
    return isBlank(value);
  }
}
