import { Component, OnInit } from '@angular/core';
import { FilterParams, User } from '@app/shared/models';
import { CrudService, Repository, UserService } from '@app/shared/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-accounts-workspace',
  templateUrl: './accounts-workspace.component.html',
  providers: [Repository, { provide: CrudService, useExisting: UserService }],
})
export class AccountsWorkspaceComponent implements OnInit {
  loading: boolean = false;
  filterParams: FilterParams = this.repo.defaultFilterParams;
  users$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );

  constructor(private repo: Repository<User>) {}

  ngOnInit(): void {
    this.onFilterParamsChange(this.filterParams);
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }
}
