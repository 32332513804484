<mat-table
  [dataSource]="fvs"
  multiTemplateDataRows
  matSort
  (matSortChange)="onSortChange($event)"
>
  <ng-container [matColumnDef]="ScreeningField.SURNAME">
    <mat-header-cell
      class="justify-content-center white-arrow"
      *matHeaderCellDef
      mat-sort-header
      [hidden]="!fvColumnsDisplay['surname']"
    >
      Foreign National
    </mat-header-cell>
    <mat-cell
      class="justify-content-between"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['surname']"
    >
      <div
        class="d-flex align-items-center justify-content-between"
        *ngIf="fv | fullname: 'surnameFirst' as fullName"
      >
        <div
          class="d-flex justify-content-start ml-2"
          [matTooltip]="fullName | titlecase"
          [class.align-wrapped]="fullName.length > 15"
        >
          {{ fullName | titlecase | shorten: 20:"..." }}
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <mat-icon
          matTooltip="FN ineligible for screening"
          color="warn"
          *ngIf="fv.screeningEligible === false"
          >warning</mat-icon
        >
        <ng-container *appIfRoles="Role.sv_admin">
          <mat-icon
            matTooltip="Average Response Time Exceeded"
            style="color: #fdd835"
            *ngIf="fv.isScreeningTimeOutstanding"
            >warning</mat-icon
          >
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="ScreeningField.REQUESTED_DATE">
    <mat-header-cell
      class="justify-content-center white-arrow"
      *matHeaderCellDef
      mat-sort-header
      [hidden]="!fvColumnsDisplay['latestScreening.requestedDate']"
    >
      {{ ScreeningFieldLabel[ScreeningField.REQUESTED_DATE] }}
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      style="text-align: center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['latestScreening.requestedDate']"
    >
      {{
        fv.latestScreening?.requestedDate | date: ScreeningDateFormat | default
      }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="ScreeningField.MODIFIED_DATE">
    <mat-header-cell
      class="justify-content-center white-arrow"
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [hidden]="!fvColumnsDisplay['latestScreening.lastModifiedDate']"
    >
      {{ ScreeningFieldLabel[ScreeningField.MODIFIED_DATE] }}
    </mat-header-cell>
    <mat-cell
      class="justify-content-center white-arrow"
      style="text-align: center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['latestScreening.lastModifiedDate']"
    >
      {{
        fv.latestScreening?.lastModifiedDate
          | date: ScreeningDateFormat
          | default
      }}</mat-cell
    >
  </ng-container>
  <ng-container [matColumnDef]="ScreeningField.COMPLETED_DATE">
    <mat-header-cell
      class="justify-content-center white-arrow"
      *matHeaderCellDef
      mat-sort-header
      [hidden]="!fvColumnsDisplay['latestScreening.completedDate']"
    >
      {{ ScreeningFieldLabel[ScreeningField.COMPLETED_DATE] }}
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      style="text-align: center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['latestScreening.completedDate']"
    >
      {{
        fv.latestScreening?.completedDate | date: ScreeningDateFormat | default
      }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="ScreeningField.STATUS">
    <mat-header-cell
      class="justify-content-center white-arrow"
      *matHeaderCellDef
      mat-sort-header
      [hidden]="!fvColumnsDisplay['latestScreening.status']"
    >
      {{ ScreeningFieldLabel[ScreeningField.STATUS] }}
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['latestScreening.status']"
    >
      <app-screening-indicator
        id="'screening-indicator-' + {{ fv.id }}"
        *ngIf="fv?.latestScreening as screening"
        [screening]="screening"
        [showStatusIcon]="false"
        [display]="'status'"
        [hasMenu]="true"
      >
      </app-screening-indicator>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="ScreeningField.RESULT">
    <mat-header-cell
      class="justify-content-center"
      style="text-align: center"
      *matHeaderCellDef
      [hidden]="!fvColumnsDisplay['latestScreening.result']"
    >
      {{ ScreeningFieldLabel[ScreeningField.RESULT] }}
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['latestScreening.result']"
    >
      <app-screening-indicator
        id="screening-result-{{ fv.id }}"
        *ngIf="fv.latestScreening?.result"
        [screening]="fv.latestScreening"
        [display]="'result'"
      >
      </app-screening-indicator>
      <span *ngIf="!fv.latestScreening?.result">N/A</span>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'totalScreenings'">
    <mat-header-cell
      class="justify-content-center"
      style="text-align: center"
      *matHeaderCellDef
      [hidden]="!fvColumnsDisplay['totalScreenings']"
    >
      Total Screenings
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['totalScreenings']"
    >
      {{ fv.allScreenings.length }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="ScreeningField.NOTES">
    <mat-header-cell
      class="justify-content-center"
      *matHeaderCellDef
      [hidden]="!fvColumnsDisplay['latestScreening.notes']"
    >
      {{ ScreeningFieldLabel[ScreeningField.NOTES] }}
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['latestScreening.notes']"
    >
      <ng-container
        *ngIf="fv.latestScreening?.notes; then hasNotes; else noNotes"
      ></ng-container>
      <ng-template #hasNotes>
        <button
          mat-icon-button
          #noteTrigger="matMenuTrigger"
          [matMenuTriggerFor]="noteMenu"
        >
          <mat-icon
            color="primary"
            [matTooltip]="'View Screening to read Notes'"
            >description</mat-icon
          >
        </button>
        <mat-menu #noteMenu>
          <div class="container">
            <div class="row">
              <div class="col">
                <span>
                  {{ fv.latestScreening?.notes }}
                </span>
              </div>
            </div>
          </div>
        </mat-menu>
      </ng-template>
      <ng-template #noNotes
        ><span [matTooltip]="'No Notes Available'"> N/A </span>
      </ng-template>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="ScreeningField.NETWORK_DOMAIN">
    <mat-header-cell
      class="justify-content-center"
      *matHeaderCellDef
      [hidden]="!fvColumnsDisplay['latestScreening.networkDomain']"
    >
      {{ ScreeningFieldLabel[ScreeningField.NETWORK_DOMAIN] }}
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['latestScreening.networkDomain']"
    >
      <mat-icon
        [svgIcon]="'alpha-' + fv.networkDomain?.toLowerCase() + '-box'"
        matTooltip="Network"
        aria-hidden="false"
        color="primary"
      ></mat-icon>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell
      class="justify-content-center"
      *matHeaderCellDef
      [hidden]="!fvColumnsDisplay['actions']"
      >Actions</mat-header-cell
    >
    <mat-cell
      class="justify-content-center"
      *matCellDef="let fv"
      [hidden]="!fvColumnsDisplay['actions']"
    >
      <button
        *ngIf="fv?.latestScreening"
        mat-icon-button
        type="button"
        #actionTrigger="matMenuTrigger"
        [matMenuTriggerFor]="actionMenu"
        matTooltip="Click For Actions"
        matTooltipPosition="right"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionMenu="matMenu">
        <button
          mat-menu-item
          [screeningUpdate]="fv"
          (triggerUpdate)="onSortChange(null)"
          id="view-button"
        >
          <mat-icon>visibility</mat-icon>
          View
        </button>
        <button
          [disabled]="!fv.isRescreenable"
          mat-menu-item
          (click)="openConfirmationDialog(fv)"
          id="new-screening-button"
          data-cy="new-screening-vetter-button"
        >
          <mat-icon svgIcon="send-circle-outline"></mat-icon>
          Rescreen
        </button>
        <button
          mat-menu-item
          [screeningHistory]="fv"
          id="open-screening-history-button"
          data-cy="screening-history-vetter-button"
        >
          <mat-icon svgIcon="clipboard-text-clock"></mat-icon>
          Screening History
        </button>
      </mat-menu></mat-cell
    >
  </ng-container>
  <mat-header-row *matHeaderRowDef="fvColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: fvColumns"></mat-row>
</mat-table>
