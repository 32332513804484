import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterParams, FAR } from '@app/shared/models';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { FarSortFieldLabels, FarSorts } from '@app/shared/services';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { FarEditRoles } from '@shared/models/role-permissions';
import { CollectionView } from '@app/shared/components';
import { FarAdvancedSearch } from '@app/foreign-access-requests/dashboard/far-advanced-search';
import {
  FARTableColumns,
  FARTableName,
} from '@app/foreign-access-requests/dashboard/collections/far-table/far-table.component';

const actionButtons: ActionButton[] = [
  {
    label: 'New FAR',
    routerLink: '/far',
    menuIcon: 'note_add',
    roles: FarEditRoles,
  },
];

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
})
export class CollectionsComponent {
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<FAR>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() loading: boolean = false;
  @Input() workspaceView: boolean = false;

  view: CollectionView = 'card';
  headerActionButtons: ActionButton[] = actionButtons;
  FarSorts = FarSorts;

  onViewChange(view: CollectionView) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly FarAdvancedSearch = FarAdvancedSearch;
  protected readonly FARTableColumns = FARTableColumns;
  protected readonly FarSortFieldLabels = FarSortFieldLabels;
  protected readonly FARTableName = FARTableName;
}
