import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordify',
})
export class WordifyPipe implements PipeTransform {
  transform(oldString: string): string {
    let newString = oldString
      .split(/(?=[A-Z])/g)
      .join(' ')
      .replace(oldString[0], oldString[0].toUpperCase());
    return newString;
  }
}
