import { Component, Input, OnInit } from '@angular/core';
import { ColumnChooserEventObservable } from './column-chooser-observable.service';
import { startCase } from 'lodash';

@Component({
  selector: 'app-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss'],
})
export class ColumnChooserComponent implements OnInit {
  @Input() dataList: string[] = [];
  @Input() labels: any;
  @Input() tableName: string = '';
  displayDataList: any[] = [];
  displayDataListCopy: any[] = [];
  columnDisplayOrder: string = 'DisplayOrder';
  filter: string = '';

  constructor(
    private _ColumnChooserEventObservable: ColumnChooserEventObservable
  ) {}

  ngOnInit(): void {
    this.displayDataList = this.formatData(this.dataList);
    this.displayDataListCopy = JSON.parse(JSON.stringify(this.displayDataList));
  }

  formatData(data: string[]): any[] {
    if (!data) return [];

    let list: any[] = [];
    for (let item of data) {
      if (item !== 'select') {
        if (!this.labels) list.push({ label: item, checked: true });
        else
          list.push({
            label: this.labels[item] || startCase(item),
            dataField: item,
            checked: true,
          });
      }
    }
    return list;
  }

  changeDisplayOrder(type: string) {
    this.columnDisplayOrder = type;

    if (this.columnDisplayOrder === 'Alphabetical') {
      this.displayDataList = this.displayDataList.sort((a, b) =>
        `${a.label}`.localeCompare(`${b.label}`)
      );
    } else {
      this.displayDataList = this.changeToDisplayOrder();
      if (this.filter.length > 0) this.filterList();
    }
  }

  changeToDisplayOrder() {
    let hold = JSON.parse(JSON.stringify(this.displayDataListCopy));
    for (let item of hold) {
      for (let cur of this.displayDataList) {
        if (item.label === cur.label) {
          item.checked = cur.checked;
          break;
        }
      }
    }
    return hold;
  }

  filterList() {
    this.displayDataList = this.displayDataListCopy.filter((data) =>
      data.label.toLowerCase().includes(this.filter.toLowerCase())
    );
    if (this.columnDisplayOrder === 'Alphabetical') {
      this.displayDataList = this.displayDataList.sort((a, b) =>
        `${a.label}`.localeCompare(`${b.label}`)
      );
    }
  }

  clear() {
    this.filter = '';
    this.filterList();
  }

  toggleAll(state: boolean) {
    for (let item of this.displayDataList) {
      item.checked = state;
    }
    this.updateCopyState();

    if (!state) {
      if (!this.filter) {
        this._ColumnChooserEventObservable.sendEvent({
          tableName: this.tableName,
          eventType: 'hideAll',
        });
      } else {
        this._ColumnChooserEventObservable.sendEvent({
          tableName: this.tableName,
          eventType: 'hideAllPartial',
          columnNameArray: this.displayDataList,
        });
      }
    } else {
      if (!this.filter) {
        this._ColumnChooserEventObservable.sendEvent({
          tableName: this.tableName,
          eventType: 'showAll',
          columns: this.dataList,
        });
      } else {
        this._ColumnChooserEventObservable.sendEvent({
          tableName: this.tableName,
          eventType: 'showAllPartial',
          columnNameArray: this.displayDataList,
        });
      }
    }
  }

  toggleColumn(column: any) {
    let eventType = '';
    let columnName = '';
    if (column.checked) eventType = 'show';
    else eventType = 'hide';

    if (column.dataField) columnName = column.dataField;
    else columnName = column.label;

    this._ColumnChooserEventObservable.sendEvent({
      tableName: this.tableName,
      eventType: eventType,
      columnName: columnName,
    });
    this.updateCopyState();
  }

  updateCopyState() {
    for (let item of this.displayDataList) {
      for (let copy of this.displayDataListCopy) {
        if (item.label === copy.label) copy.checked = item.checked;
      }
    }
  }

  public static updateTableDisplay(
    event: ColumnToggleEvent,
    columns: any,
    displayColumns: any
  ) {
    switch (event.eventType) {
      case 'show': {
        if (event.columnName) displayColumns[event.columnName] = true;
        break;
      }
      case 'hide': {
        if (event.columnName) displayColumns[event.columnName] = false;
        break;
      }
      case 'hideAll': {
        for (let item of columns) {
          displayColumns[item] = false;
        }
        break;
      }
      case 'showAll': {
        for (let item of columns) {
          displayColumns[item] = true;
        }
        break;
      }
      case 'hideAllPartial': {
        if (event.columnNameArray) {
          for (let item of columns) {
            for (let column of event.columnNameArray) {
              if (item === column.label || item === column.dataField) {
                displayColumns[item] = false;
                break;
              }
            }
          }
        }
        break;
      }
      case 'showAllPartial': {
        if (event.columnNameArray) {
          for (let item of columns) {
            for (let column of event.columnNameArray) {
              if (item === column.label || item === column.dataField) {
                displayColumns[item] = true;
                break;
              }
            }
          }
        }
        break;
      }
    }
    return displayColumns;
  }
}
export interface ColumnToggleEvent {
  eventType:
    | 'show'
    | 'hide'
    | 'hideAll'
    | 'showAll'
    | 'hideAllPartial'
    | 'showAllPartial'
    | string;
  columnName?: string;
  columnNameArray?: any[];
  columns?: string[];
  tableName?: string;
}
