import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User, Role } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { AppConfigValue, UserSortFieldLabels } from '@shared/services';
import { UserEditRoles } from '@shared/models/role-permissions';
import { AccountsAdvancedSearch } from '@app/admin/accounts/accounts-collection/accounts-advanced-search';
import {
  UserTableColumns,
  UserTableName,
} from '@app/admin/accounts/accounts-collection/account-user-table/account-user-table.component';

const newUserAction: ActionButton = {
  label: 'New User Account',
  menuIcon: 'note_add',
  userDialog: true,
  roles: UserEditRoles,
};

const allActions: ActionButton[] = [newUserAction];

@Component({
  selector: 'app-accounts-collection',
  templateUrl: 'accounts-collection.component.html',
  styleUrls: ['accounts-collection.component.scss'],
})
export class AccountsCollectionsComponent {
  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<User>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  actionButtons: ActionButton[];
  showingInactive: boolean = false;

  constructor() {
    this.actionButtons = this.limitedUserAdmin ? [] : allActions;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly AccountsAdvancedSearch = AccountsAdvancedSearch;
  protected readonly UserTableColumns = UserTableColumns;
  protected readonly UserSortFieldLabels = UserSortFieldLabels;
  protected readonly UserTableName = UserTableName;
}
