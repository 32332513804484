import {
  Component,
  Input,
  Injector,
  AfterViewInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FilterParams, PageableCollection, Group } from '@app/shared/models';
import { Observable } from 'rxjs';
import { GroupService } from '@app/shared/services/';
import { BaseMatSelectComponent } from '../base-mat-select.component';
import { FloatLabelType } from '@angular/material/form-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGroupComponent
  extends BaseMatSelectComponent<Group>
  implements AfterViewInit
{
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() filterLabel: string = 'Search';

  @Input() multiple: boolean;
  @Input() label: string = 'Select a Foreign National Group';
  @Input() excludeById: string = '';
  @Input() restrictCurrentOrganization: boolean = true;
  @Input() organizationId: string = '';

  @Output() groupIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set groupId(_groupId: string) {
    this.loadGroupId(_groupId);
  }

  get fvId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams = new FilterParams({
    currentNetworkDomain: true,
    currentOrganization: this.restrictCurrentOrganization,
    sort: {
      active: 'name',
      direction: 'asc',
    },
  });

  constructor(injector: Injector, private groupService: GroupService) {
    super(injector);
  }

  loadGroupId(groupId: string) {
    if ((!groupId && !this.value) || groupId === this.value?.id) return;

    if (!groupId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: groupId };
    this.groupService
      .get(groupId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  ngAfterViewInit(): void {
    this.baseFilterParams['excludeGroupId'] = this.excludeById;
    this.baseFilterParams['currentOrganization'] =
      this.restrictCurrentOrganization;

    if (this.organizationId)
      this.baseFilterParams['ownerOrganizationId'] = this.organizationId;

    super.ngOnInit();
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.groupIdChange.emit(this.fvId);
  }

  searchItems(params: FilterParams): Observable<PageableCollection<Group>> {
    return this.groupService.find(params);
  }

  getSelectedText(values: any) {
    if (values?.length) {
      const singleItem = `${values[0].name}`;
      const multipleItems = `${values.length} Options Selected`;
      return values.length > 1 ? multipleItems : singleItem;
    }

    return `${values?.name}`;
  }
}
