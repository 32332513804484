<!--USER ACCT-->
<div class="container" *ngIf="systemUser$ | async as systemUser">
  <div class="acct-button-bar d-flex flex-row-reverse">
    <button
      appChangePassword
      mat-stroked-button
      id="acct-change-password-button"
      *ngIf="auth.canChangePassword"
      aria-label="Change Password"
    >
      <mat-icon>lock</mat-icon> Change Password
    </button>
    <button
      appAuthEventsDialog
      mat-stroked-button
      id="acct-activity-button"
      *ngIf="summary"
      class="mr-2"
      aria-label="Account Activity"
    >
      <mat-icon>history</mat-icon> Account Activity
    </button>
  </div>
  <div class="flex-column text-center">
    <app-user-avatar [size]="96" [user]="systemUser"></app-user-avatar>
    <h4>{{ systemUser | fullname }}</h4>
    <div class="d-flex flex-column align-items-center p-3 mb-5">
      <app-user-role-chips
        [roles]="systemUser?.roles"
        [selectable]="false"
      ></app-user-role-chips>
    </div>
  </div>
  <div class="d-flex flex-row flex-wrap align-items-stretch mb-5">
    <div class="col-md-6 col-12 d-flex align-items-stretch">
      <mat-card
        appearance="outlined"
        class="card-section mat-elevation-z0 m-3 col"
      >
        <mat-card-header>
          <mat-card-title>Basic Info</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="review-container stack-rows-md p-1">
            <div class="data-row">
              <div class="data-key">Given Name</div>
              <div class="data-value">{{ systemUser.givenName }}</div>
            </div>
            <div class="data-row">
              <div class="data-key">Surname</div>
              <div class="data-value">{{ systemUser.surname }}</div>
            </div>
            <div class="data-row">
              <div class="data-key">Organization</div>
              <div class="data-value">
                <mat-icon>group_work</mat-icon>
                {{ systemUser?.organization | getOrgName: "long" | default }}
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-6 col-12 d-flex align-items-stretch">
      <mat-card
        appearance="outlined"
        class="card-section mat-elevation-z0 m-3 col"
      >
        <mat-card-header class="d-flex flex-row">
          <mat-card-title>Contact Info</mat-card-title>
          <span class="col"></span>
        </mat-card-header>
        <mat-card-content>
          <div class="review-container stack-rows-md p-1">
            <div class="data-row">
              <div class="data-key">Email</div>
              <div class="data-value">
                <mat-icon>email</mat-icon> {{ systemUser.email }}
              </div>
            </div>
            <div class="data-row">
              <div class="data-key">Work Phone</div>
              <div class="data-value">
                <mat-icon>phone</mat-icon>
                {{ systemUser?.phone | default }}
              </div>
            </div>
            <div class="data-row">
              <div class="data-key">Mobile Phone</div>
              <div class="data-value">
                <mat-icon>smartphone</mat-icon>
                {{ systemUser?.mobilePhone | default }}
              </div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions class="d-flex flex-row">
          <span class="col"></span>
          <button
            appEditUserContactInfo
            mat-stroked-button
            id="edit-contact-info-button"
            aria-label="Edit contact Info"
          >
            <mat-icon svgIcon="square-edit-outline"></mat-icon> Edit
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
