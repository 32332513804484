<form #form="ngForm" *ngIf="value?.result; else noResponse">
  <section>
    <ng-container *ngIf="!overrideResults">
      <div class="d-flex py-3">
        <div style="flex: 0 0 33%">
          <h4>Results</h4>
          <div>{{ value | screeningLabel: "result" }}</div>
        </div>
        <div>
          <h4>Notes</h4>
          <div>{{ value?.notes }}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="overrideResults">
      <div>
        <mat-form-field [appearance]="appearance">
          <mat-label>Result</mat-label>
          <mat-select
            #matSelect="matSelect"
            id="screening-dialog-result-override"
            [(ngModel)]="value.result"
            (ngModelChange)="onUpdateResult($event)"
            name="select-result"
          >
            <mat-option [value]="result.MachineGreen">Machine Green</mat-option>
            <mat-option [value]="result.ReviewedGreen"
              >Reviewed Green</mat-option
            >
            <mat-option [value]="result.ReviewedRed">Reviewed Red</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field [appearance]="appearance">
          <mat-label>Notes</mat-label>
          <textarea
            name="notes"
            maxlength="2000"
            rows="3"
            cdkFocusInitial
            matInput
            [(ngModel)]="value.notes"
            (ngModelChange)="emitChangeEvent()"
          ></textarea>
        </mat-form-field>
      </div>
    </ng-container>
  </section>

  <ng-container *highEnvFeatures="true">
    <div *ngIf="isRed">
      <app-screening-code-display
        [overrideCode]="overrideResults"
        [derogItems]="value.derogatoryInformation!"
      ></app-screening-code-display>
    </div>
  </ng-container>
</form>

<ng-template #noResponse>
  <div *ngIf="value?.status === status.New">Awaiting Screening Submission</div>
  <div *ngIf="value?.status === status.InProcess">Awaiting a Response</div>
</ng-template>
