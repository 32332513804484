<mat-toolbar
  class="mat-elevation-z4 app-toolbar position-relative"
  color="primary"
>
  <mat-toolbar-row
    *ngIf="showBanner"
    class="classification-banner d-flex flex-column"
  >
    <span class="col text-uppercase text-center">{{ classification }}</span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <button
      id="main-nav-button"
      style="margin-left: -5px"
      mat-icon-button
      matTooltip="Click for Navigation Options"
      [matMenuTriggerFor]="navMenu"
      aria-label="navigation"
    >
      <mat-icon style="margin-bottom: 5px">menu</mat-icon>
    </button>
    <!--Navigation Menu-->
    <mat-menu #navMenu="matMenu" [class]="'menu--main'">
      <button
        id="home-nav-menu-button"
        mat-menu-item
        [routerLink]="'/'"
        [routerLinkActive]="'font-weight-bold active'"
        [routerLinkActiveOptions]="homeLinkOptions"
      >
        <mat-icon>home</mat-icon> <span> Home </span>
      </button>
      <button
        id="fvs-nav-menu-button"
        mat-menu-item
        [routerLink]="'/fvs'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="FVViewRoles"
      >
        <mat-icon>perm_identity</mat-icon>
        <span> Foreign Nationals (FN) </span>
      </button>
      <button
        id="fars-nav-menu-button"
        mat-menu-item
        [routerLink]="'/fars'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="FarViewRoles"
      >
        <mat-icon>language</mat-icon>
        <span> Foreign Access Requests (FAR) </span>
      </button>
      <button
        id="screening-nav-menu-button"
        mat-menu-item
        [routerLink]="'/screening'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="ScreeningRoles"
      >
        <mat-icon>fingerprint</mat-icon> <span> Screening Review </span>
      </button>
      <button
        id="location-nav-menu-button"
        mat-menu-item
        [routerLink]="'/locations'"
        [routerLinkActive]="'font-weight-bold-active'"
        *appIfRoles="LocViewRoles"
      >
        <mat-icon svgIcon="office-building-marker"></mat-icon>
        <span> Locations </span>
      </button>
      <button
        id="group-nav-menu-button"
        mat-menu-item
        [routerLink]="'/groups'"
        [routerLinkActive]="'font-weight-bold-active'"
        *appIfRoles="GroupViewRoles"
      >
        <mat-icon svgIcon="group"></mat-icon>
        <span> Group Management </span>
      </button>
      <ng-container *appIfRoles="AdminViewRoles">
        <div
          class="text-white bg-primary admin-panel py-2"
          [stopPropagation]="['click']"
        >
          <mat-icon svgIcon="security" class="mx-3"></mat-icon
          ><span>Admin</span>
        </div>
      </ng-container>
      <button
        id="metrics-nav-menu-button"
        mat-menu-item
        [routerLink]="'/metrics'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="MetricsViewRoles"
      >
        <mat-icon svgIcon="chart-box"></mat-icon> <span> Metrics </span>
      </button>
      <button
        id="monitoring-nav-menu-button"
        mat-menu-item
        [routerLink]="'/monitoring'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="MonitoringViewRoles"
      >
        <mat-icon svgIcon="monitor-dashboard"></mat-icon>
        <span>System Monitoring</span>
      </button>
      <button
        id="admin-nav-menu-button"
        mat-menu-item
        [routerLink]="'/organizations'"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="AdminViewRoles"
      >
        <mat-icon svgIcon="home-city"></mat-icon> <span> Organizations </span>
      </button>
      <button
        mat-menu-item
        id="system-alerts-menu-button"
        [routerLink]="['systemalerts']"
        [routerLinkActive]="'font-weight-bold active'"
        *appIfRoles="SystemAlertsEditRoles"
      >
        <mat-icon svgIcon="bullhorn-variant-outline"></mat-icon>
        <span> System Alerts </span>
      </button>
      <ng-container *ngIf="!disableUserAdmin">
        <button
          id="admin-nav-menu-button"
          mat-menu-item
          [routerLink]="'/admin'"
          [routerLinkActive]="'font-weight-bold active'"
          *appIfRoles="AdminViewRoles"
        >
          <mat-icon>settings</mat-icon> <span> User Administration </span>
        </button>
      </ng-container>
      <ng-container
        *appIfConfig="{ key: 'orgAdminApprovalsEnabled', value: true }"
      >
        <ng-container *ngIf="lowEnvFeatures">
          <button
            mat-menu-item
            [routerLink]="'/approvals'"
            [routerLinkActive]="'font-weight-bold active'"
            *appIfRoles="UserApproverRoles"
          >
            <mat-icon svgIcon="account-reactivate-outline"></mat-icon>
            <span>Approvals</span>
          </button>
        </ng-container>
      </ng-container>
    </mat-menu>

    <!--Logo-->
    <a [routerLink]="'/'" class="d-flex text-white ml-3">
      <mat-icon [citadelLogo]="'horizontal'"></mat-icon>
    </a>
    <div class="flex-fill"></div>
    <!-- Help -->
    <button
      id="help-toolbar-button"
      class="mr-2"
      mat-icon-button
      matTooltip="Help"
      [matMenuTriggerFor]="helpMenu"
      aria-label="help"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <mat-menu #helpMenu="matMenu">
      <a
        id="help-button"
        mat-menu-item
        href="{{ helpUrl }}"
        target="_blank"
        aria-label="Help"
      >
        <mat-icon>help_center</mat-icon> Help
      </a>
      <button id="about-button" mat-menu-item appAboutDialog aria-label="About">
        <mat-icon svgIcon="information-outline"></mat-icon> About
      </button>
      <a
        id="contact-support-button"
        mat-menu-item
        appContactSupport
        aria-label="Contact Support"
      >
        <mat-icon svgIcon="face-agent"></mat-icon> Contact Support
      </a>
    </mat-menu>
    <!-- User Profile Menu-->
    <button
      id="user-profile-toolbar-button"
      class="ml-2"
      mat-icon-button
      matBadge
      [matBadgeIconHidden]="!userService.filteredRoles?.length"
      matBadgeColor="accent"
      matBadgeOverlap="true"
      matBadgePosition="below after"
      matBadgeIcon="account-switch-outline"
      [matMenuTriggerFor]="userProfileMenu"
      aria-label="profile"
      aria-hidden="false"
    >
      <div class="user-profile-icon">
        <app-user-avatar [size]="40" [user]="systemUser"></app-user-avatar>
      </div>
    </button>
    <mat-menu #userProfileMenu="matMenu">
      <!-- Help -->
      <button
        id="user-profile-nav-button"
        mat-menu-item
        [routerLink]="['/profile']"
      >
        <app-user-avatar class="pl-1" [user]="systemUser"></app-user-avatar>
        <span class="user-name"
          >{{ systemUser.givenName }} {{ systemUser.surname }}</span
        >
      </button>
      <mat-divider></mat-divider>
      <button
        *appSwitchRoles
        mat-menu-item
        id="acct-switch-roles-button"
        aria-label="Switch Roles"
      >
        <mat-icon
          aria-hidden="false"
          [matBadge]="
            userService.filteredRoles?.length
              ? userService.filteredRoles.length
              : undefined
          "
          matBadgeColor="accent"
          [matBadgeHidden]="userService.filteredRoles?.length ? false : true"
          svgIcon="account-switch-outline"
        ></mat-icon>
        Switch Roles
      </button>
      <button
        id="change-password-button"
        *ngIf="auth.canChangePassword"
        mat-menu-item
        appChangePassword
        aria-label="Change Password"
      >
        <mat-icon>lock</mat-icon> Change Password
      </button>
      <button
        id="logout-button"
        mat-menu-item
        (click)="logout()"
        aria-label="logout"
      >
        <mat-icon>input</mat-icon> Logout
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
