import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  FilterParams,
  ForeignVisitor,
  Role,
  ScreeningDateFormat,
  ScreeningResult,
} from '@app/shared/models';
import { Sort } from '@angular/material/sort';
import { ColumnChooserEventObservable } from '@app/shared/components/column-chooser/column-chooser-observable.service';
import {
  ColumnChooserComponent,
  ConfirmDialogComponent,
} from '@app/shared/components';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ScreeningService } from '@app/shared/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-screening-table',
  templateUrl: './screening-table.component.html',
  styleUrls: ['./screening-table.component.scss'],
})
export class ScreeningTableComponent implements OnInit, OnDestroy {
  @Input() fvs: ForeignVisitor[];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  ScreeningDateFormat = ScreeningDateFormat;

  Role = Role;

  ScreeningField = ScreeningField;
  ScreeningFieldLabel = ScreeningFieldLabels;
  ScreeningResult = ScreeningResult;
  private ngUnsubscribe = new Subject<void>();

  fvColumns: string[] = [
    ScreeningField.SURNAME,
    ScreeningField.REQUESTED_DATE,
    ScreeningField.MODIFIED_DATE,
    ScreeningField.COMPLETED_DATE,
    ScreeningField.STATUS,
    ScreeningField.RESULT,
    ScreeningField.NOTES,
    ScreeningField.NETWORK_DOMAIN,
    'totalScreenings',
    'actions',
  ];

  fvColumnsDisplay: any = {
    surname: true,
    'latestScreening.requestedDate': true,
    'latestScreening.status': true,
    'latestScreening.notes': true,
    'latestScreening.networkDomain': true,
    'latestScreening.completedDate': true,
    'latestScreening.lastModifiedDate': true,
    'latestScreening.result': true,
    totalScreenings: true,
    actions: true,
  };

  constructor(
    private _ColumnChooserEventObservable: ColumnChooserEventObservable,
    private screeningService: ScreeningService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.ScreeningFieldLabel['totalScreenings'] = 'Total Screenings';
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      this.fvColumnsDisplay = ColumnChooserComponent.updateTableDisplay(
        event,
        this.fvColumns,
        this.fvColumnsDisplay
      );
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSortChange(sort: Sort | null) {
    if (sort !== null) {
      this.filterParams.sortBy = sort.active;
      this.filterParams.direction = sort.direction.toUpperCase();
    }
    this.filterParamsChange.emit(this.filterParams);
  }

  rescreen(fv: ForeignVisitor) {
    this.screeningService
      .rescreen({ id: fv.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  openConfirmationDialog(
    fv: ForeignVisitor
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for ${fv.givenName} ${fv.surname}.`,
      performAction: () => this.rescreen(fv),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }
}

export enum ScreeningField {
  SURNAME = 'surname',
  VIP = 'vip',
  STATUS = 'latestScreening.status',
  NOTES = 'latestScreening.notes',
  NETWORK_DOMAIN = 'latestScreening.networkDomain',
  REQUESTED_DATE = 'latestScreening.requestedDate',
  COMPLETED_DATE = 'latestScreening.completedDate',
  MODIFIED_DATE = 'latestScreening.lastModifiedDate',
  RESULT = 'latestScreening.result',
}

export const ScreeningFieldLabels: { [key: string]: string } = {
  [ScreeningField.SURNAME]: 'Foreign National',
  [ScreeningField.VIP]: 'Expedited',
  [ScreeningField.NOTES]: 'Notes Available',
  [ScreeningField.NETWORK_DOMAIN]: 'Fabric',
  [ScreeningField.STATUS]: 'Screening Status',
  [ScreeningField.REQUESTED_DATE]: 'Requested Date',
  [ScreeningField.COMPLETED_DATE]: 'Completed Date',
  [ScreeningField.MODIFIED_DATE]: 'Modified Date',
  [ScreeningField.RESULT]: 'Screening Result',
};
