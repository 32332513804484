import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Screening, ForeignVisitor, ScreeningStatus } from '@app/shared/models';
import { ScreeningService } from '@app/shared/services';
import { ScreeningRoles } from '@shared/models/role-permissions';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-screening-dialog',
  templateUrl: './screening-dialog.component.html',
  styleUrls: ['./screening-dialog.component.scss'],
})
export class ScreeningDialogComponent implements OnInit {
  busy: boolean = false;
  cacheScreening: Partial<Screening> = {};
  confirmUsp: boolean = false;
  latestScreening!: Screening;
  overrideResults: boolean = false;
  ScreeningRoles = ScreeningRoles;
  status = ScreeningStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public fv: ForeignVisitor,
    private dialogRef: MatDialogRef<ScreeningDialogComponent>,
    private screeningService: ScreeningService
  ) {}

  ngOnInit(): void {
    this.latestScreening = cloneDeep(this.fv.latestScreening) as Screening;
    this.confirmUsp = false;
  }

  onOverrideResults() {
    this.cacheScreening = cloneDeep(this.latestScreening);
    this.overrideResults = true;
  }

  onCancel() {
    this.latestScreening = this.cacheScreening;
    this.cacheScreening = {};
    this.overrideResults = false;
  }

  onSubmit() {
    this.busy = true;
    this.screeningService.save(this.latestScreening).subscribe({
      next: (_) => {
        this.dialogRef.close();
      },
      error: (err) => (this.busy = false),
    });
  }

  getErrorMessage(error: any) {
    if (error.legacyValue) {
      return `Result contains a Legacy Value`;
    }
  }
}
