import { Pipe, PipeTransform } from '@angular/core';
import { Visa } from '@app/shared/models/visa.model';
import { ForeignVisitor } from '../models';

@Pipe({
  name: 'currentVisa',
})
export class CurrentVisaPipe implements PipeTransform {
  transform(fv: ForeignVisitor) {
    if (fv.visas && fv.visas.length > 0) {
      let visas: Visa[] = fv.visas.slice().sort((a, b) => {
        if (a.expirationDate && b.expirationDate) {
          const dateA = new Date(a.expirationDate).valueOf();
          const dateB = new Date(b.expirationDate).valueOf();
          return (dateA - dateB) * -1;
        } else {
          return 0;
        }
      });
      return visas[0];
    } else {
      return null;
    }
  }
}
