import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterParams, OrgLocation, Role } from '@app/shared/models';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { OrgLocationSortLabels, OrgLocationSorts } from '@shared/services';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { LocationAdvancedSearch } from '@app/locations/collection/location-advanced-search';
import {
  LocationTableColumns,
  LocationTableName,
} from '@app/locations/collection/location-collection-table/location-collection-table.component';

const actionButtons: ActionButton[] = [
  {
    label: 'Bulk Upload',
    bulkUpload: 'Org',
    menuIcon: 'input',
    roles: [Role.sv_admin, Role.sv_org_admin, Role.sv_org_user],
    color: 'primary',
  },
  {
    label: 'New Location',
    menuIcon: 'note_add',
    locationEdit: true,
    roles: [Role.sv_admin, Role.sv_org_admin, Role.sv_org_user],
  },
];
@Component({
  selector: 'app-locations-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class LocationsCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<OrgLocation> =
    {} as PageableCollection<OrgLocation>;
  @Input() placeholder: string = 'Filter Criteria (e.g): Name, Address';
  @Input() workspaceView: boolean = false;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  actionButtons: ActionButton[] = actionButtons;
  OrgLocationSorts = OrgLocationSorts;

  onFilterParamsChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly LocationAdvancedSearch = LocationAdvancedSearch;
  protected readonly LocationTableColumns = LocationTableColumns;
  protected readonly OrgLocationSortLabels = OrgLocationSortLabels;
  protected readonly LocationTableName = LocationTableName;
}
