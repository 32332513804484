import { Pipe, PipeTransform } from '@angular/core';
import { FAR } from '../models/far.model';

@Pipe({
  name: 'getNumFvs',
})
export class GetNumFvsPipe implements PipeTransform {
  transform(component: FAR): number {
    if (component.foreignVisitors && component.foreignVisitors.length > 0) {
      return component.foreignVisitors.length;
    } else {
      return 0;
    }
  }
}
