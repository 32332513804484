import {
  FilterConfig,
  FilterConfigType,
} from '@shared/components/advanced-search/filter-config';
import { Role } from '@shared/models';

export const LocationAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Location Name',
    name: 'name',
    col: 12,
  },
  {
    type: FilterConfigType.COUNTRY,
    label: 'Country',
    name: 'countryCode',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Address Line 1',
    name: 'line1',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Address Line 2',
    name: 'line2',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'City',
    name: 'city',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'State/Province',
    name: 'stateProvince',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Zip/Postal Code',
    name: 'postalCode',
  },
  {
    type: FilterConfigType.BUTTON_TOGGLE,
    label: 'Unresolved Locations',
    name: 'unresolvedGPS',
    options: [
      { label: 'All', val: null },
      { label: 'No Geocodes', val: 'true' },
      { label: 'Geocoded', val: 'false' },
    ],
    optLabel: 'label',
    optVal: 'val',
    col: 12,
    ifRole: [Role.sv_admin, Role.sv_help_desk],
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_vetter,
      Role.sv_org_admin,
    ],
  },
];
