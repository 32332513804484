<h5 mat-dialog-title>Delete Foreign National</h5>
<mat-dialog-content data-cy="delete-fv-form">
  <form #deleteFVForm="ngForm">
    Are you sure you want to delete {{ data.givenName }} {{ data.surname }}?
    This action cannot be undone.
    <div class="container mt-3">
      <div class="row justify-content-start align-items-center">
        <mat-icon svgIcon="alert" color="warn"></mat-icon>
        <mat-checkbox
          data-cy="usp-checkbox"
          class="ml-2"
          color="warn"
          [(ngModel)]="isUsp"
          name="isUspCheckbox"
        ></mat-checkbox>
        <div class="col">
          Please check here if this is a <strong> U.S. Person (USP) </strong> or
          a
          <strong> Lawful Permanent Resident (LPR). </strong>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions data-cy="delete-actions" class="row justify-content-end">
  <button
    data-cy="cancel-delete"
    mat-button
    [mat-dialog-close]
    id="cancel-button"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="confirmDelete()"
    id="continue-button"
    data-cy="confirm-delete-button"
  >
    Continue
  </button>
</mat-dialog-actions>
