import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { ScreeningDrawerObservable } from './screening-drawer-observable.service';
import { takeUntil, tap } from 'rxjs/operators';
import { FilterParams } from '@app/shared/models';
import { CrudService, FvService, Repository } from '@app/shared/services';

@Component({
  selector: 'app-screening-review',
  templateUrl: 'screening.component.html',
  styleUrls: ['screening.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FvService }],
})
export class ScreeningComponent implements OnInit, OnDestroy {
  showHistory: boolean = false;
  loading: boolean = false;
  filterParams = new FilterParams({
    sortBy: 'latestScreening.requestedDate',
    pageSize: 20,
    pageNum: 0,
    screeningEligible: true,
  });

  fvs$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );

  get queryParamsSnapshot() {
    return this.repo.getQueryParamsSnapshot();
  }

  private unSubscribe = new Subject<void>();

  constructor(
    private repo: Repository<ForeignVisitor>,
    private _ScreeningDrawerObservable: ScreeningDrawerObservable
  ) {}

  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);

    this._ScreeningDrawerObservable.ScreeningDrawerObservable$.pipe(
      takeUntil(this.unSubscribe)
    ).subscribe((data: any) => {
      if (data !== 'refresh') this.showHistory = data;
    });
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
