import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Address,
  ForeignVisitor,
  ContactEmail,
  Passport,
  Visa,
  ContactPhoneNumber,
  FAR,
} from '@app/shared/models';
import {
  STEPPER_GLOBAL_OPTIONS,
  StepperSelectionEvent,
} from '@angular/cdk/stepper';
import { FvCreationService } from '@app/foreign-visitors/fv-creation.service';
import { AlertService, FarService, FvService } from '@app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { VisaComponent } from './visa/visa.component';
import { PassportComponent } from './passport/passport.component';
import { BiographicComponent } from './biographic/biographic.component';
import { AttachmentsComponent } from '../../shared/components/attachments/attachments.component';
import { Subject } from 'rxjs';
import { ContactComponent } from './contact/contact.component';
import { VisitorBaselineComponent } from './visitor-baseline/visitor-baseline.component';
import { take, takeUntil } from 'rxjs/operators';
import { hasValue, hasValues } from '@app/shared/helpers/has-values';
import { HttpContext } from '@angular/common/http';
import { SUPPRESS_MESSAGE } from '@app/shared/helpers/success-message.interceptor';
import { MatDialog } from '@angular/material/dialog';
import { SelectFarComponent } from '@app/shared/controls/select-far/select-far.component';

@Component({
  selector: 'app-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['creation.component.scss'],
  providers: [
    FvCreationService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class FVComponent implements OnInit, OnDestroy, AfterViewChecked {
  /** Emits when the component is destroyed. */
  private readonly _destroyed = new Subject<void>();

  busy: boolean = false;
  isFVInformationValid: boolean = false;
  invalid = true;
  result: boolean;
  error: '';
  // creates an FV object
  fv: ForeignVisitor = {};
  fvEmail: ContactEmail = {};
  fvPhone: ContactPhoneNumber = {};
  passport: Passport;
  visa: Visa;
  address: Address;
  baselineEmail: ContactEmail = {};

  //Baseline Step/Control
  @ViewChild('baselineInfoStep')
  baselineInfoStep: MatStep;

  @ViewChild(VisitorBaselineComponent)
  baselineInfoControl: VisitorBaselineComponent;

  //Identification Step/Control
  @ViewChild('foreignVisitorInfoStep')
  biographicInfoStep: MatStep;

  @ViewChild(BiographicComponent)
  biographicInfoControl: BiographicComponent;

  // Travel Step/Controls
  @ViewChild('travelInfoStep')
  passportInfoStep: MatStep;

  @ViewChild(PassportComponent)
  passportInfoControl: PassportComponent;

  @ViewChild('travelInfoStep')
  visaInfoStep: MatStep;

  @ViewChild(VisaComponent)
  visaInfoControl: VisaComponent;

  //Contact Step/Control
  @ViewChild('contactInfoStep')
  contactInfoStep: MatStep;

  @ViewChild('contactForm')
  contactInfoControl: ContactComponent;

  @ViewChild(AttachmentsComponent, { static: false })
  attachmentsComponent: AttachmentsComponent;

  @ViewChild('stepper')
  stepper: MatStepper;

  constructor(
    private changeDetect: ChangeDetectorRef,
    private fvService: FvService,
    private farService: FarService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let farId = this.route.snapshot.params['id'];
    if (farId) {
      this.farService
        .get(farId)
        .pipe(take(1))
        .subscribe((res) => {
          this.fv.foreignAccessRequests = [];
          this.fv.foreignAccessRequests.push(res);
        });
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  //perform actions based upon where we are in the form
  public onStep(event: StepperSelectionEvent) {
    // \/\/ the index after the event \/\/
    const index = event.previouslySelectedStep;
    const refreshIndex = event.selectedStep;
    // this will capture the contents of the form so that we
    // can build the FV as we progress page to page.

    //BaselineComponentStep
    if (index === this.baselineInfoStep) {
      this.baselineInfoControl.addBaseline();
    }
    if (refreshIndex === this.baselineInfoStep) {
      this.baselineInfoControl.refreshFilterParams();
    }
    //BioComponentStep
    if (index === this.biographicInfoStep) {
      this.biographicInfoControl.addFVBio();
    }
    //PassportComponentStep
    if (index === this.passportInfoStep) {
      this.passportInfoControl.addPassport();
    }
    //VisaComponentStep
    if (index === this.visaInfoStep) {
      this.visaInfoControl.addVisa();
    }
    if (index === this.contactInfoStep) {
      this.baselineEmail = this.fvEmail;
      this.baselineInfoControl.refreshFilterParams();
    }

    this.stageFV();
  }

  addEmail(email: ContactEmail) {
    if (hasValues(this.baselineEmail) && !hasValues(this.fvEmail)) {
      this.fvEmail = JSON.parse(JSON.stringify(this.baselineEmail));
    }
    if (hasValues(email) && email !== null) {
      this.fv.emails = [email];
    }
  }

  goBack(stepper: MatStepper) {
    // Move backward in the form with the "Back" button
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    // Move forward in the form with the "Next" button
    stepper.next();
  }

  updatePassport(event: Passport) {
    this.passport = event;
  }

  updateAddress(event: Address) {
    this.address = event;
  }

  addVisas() {
    // Adding Visa to Passport
    if (this.passport && this.visa) {
      this.fv.visas = [this.visa];
    }
  }

  addAddress() {
    // Adding Address to fv
    if (hasValue(this.address)) {
      this.fv.address = this.address;
    }
  }

  addPassport() {
    // Adding Passport to fv
    if (this.passport) {
      this.fv.passports = [this.passport];
    }
  }

  addPhone() {
    if (hasValue(this.fvPhone?.number)) {
      this.fv.phoneNumbers = [this.fvPhone];
    }
  }

  stageFV(): void {
    this.addEmail(this.fvEmail);
    this.addPhone();
    this.addVisas();
    this.addAddress();
    this.addPassport();
  }

  onCreate(): void {
    this.busy = true;
    this.stageFV();
    const hasAddress = this.checkAddress(this.fv.address);
    if (
      this.fv.passports &&
      this.fv.passports.length &&
      !this.fv.passports[0].number &&
      !this.fv.passports[0].issuingCountryCode
    ) {
      this.fv.passports.pop();
    }

    if (hasAddress) {
      this.fvService
        .saveAddressThenUpdateModel(
          this.fv,
          new HttpContext().set(SUPPRESS_MESSAGE, true)
        )
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          (result) => {
            this.router.navigate([`/fv/${result.id}`], {
              relativeTo: this.route,
            });
          },
          (err) => (this.busy = false)
        );
    } else {
      this.fvService
        .save(this.fv, new HttpContext().set(SUPPRESS_MESSAGE, true))
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          (result) => {
            this.router.navigate([`/fv/${result.id}`], {
              relativeTo: this.route,
            });
          },
          (err) => (this.busy = false)
        );
    }
  }

  checkAddress(address?: Address) {
    if (
      address?.line1 ||
      address?.line2 ||
      address?.city ||
      address?.countryCode ||
      address?.stateProvince ||
      address?.postalCode
    )
      return true;
    return false;
  }

  updateFarList(far: FAR) {
    if (!this.fv.foreignAccessRequests) this.fv.foreignAccessRequests = [];
    this.fv.foreignAccessRequests.push(far);
  }
}
