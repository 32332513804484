import { Component, Input } from '@angular/core';
import { Role, User } from '@app/shared/models';
import { AuthService } from '@app/auth/auth.service';
import { IsActiveMatchOptions } from '@angular/router';
import {
  AdminViewRoles,
  FarViewRoles,
  FVViewRoles,
  GroupViewRoles,
  LocViewRoles,
  MetricsViewRoles,
  MonitoringViewRoles,
  ScreeningRoles,
  SystemAlertsEditRoles,
  UserApproverRoles,
} from '@shared/models/role-permissions';
import { AppConfigService } from '@shared/services/app-config.services';
import { UserService } from '@shared/services';

interface NavMenuItem {
  routerLink: string;
  roles: Role[];
  icon: string;
  label: string;
}

@Component({
  selector: 'app-application-toolbar',
  templateUrl: './application-toolbar.component.html',
  styleUrls: ['./application-toolbar.component.scss'],
})
export class ApplicationToolbarComponent {
  FVViewRoles = FVViewRoles;
  FarViewRoles = FarViewRoles;
  LocViewRoles = LocViewRoles;
  ScreeningRoles = ScreeningRoles;
  AdminViewRoles = AdminViewRoles;
  MetricsViewRoles = MetricsViewRoles;
  SystemAlertsEditRoles = SystemAlertsEditRoles;
  UserApproverRoles = UserApproverRoles;
  GroupViewRoles = GroupViewRoles;

  homeLinkOptions: IsActiveMatchOptions = {
    fragment: 'exact',
    matrixParams: 'exact',
    paths: 'exact',
    queryParams: 'ignored',
  };

  @Input() systemUser: User;

  classification?: string;
  showBanner: boolean;
  disableUserAdmin?: boolean;
  lowEnvFeatures?: boolean;
  helpUrl?: string;
  rolesFiltered = false;

  constructor(
    public auth: AuthService,
    private config: AppConfigService,
    public userService: UserService
  ) {
    this.rolesFiltered = !!this.userService.filteredRoles?.length;
    this.disableUserAdmin = config.get('disableUserAdmin');
    this.classification = config.get('classification');
    this.helpUrl = this.config.get('helpLink');
    this.lowEnvFeatures = this.config.get('lowEnvFeatures', false);
    this.showBanner =
      (this.classification?.length &&
        this.classification.toUpperCase() !== 'UNCLASSIFIED') ||
      false;
  }

  Role = Role;

  logout() {
    this.auth.logout();
  }

  protected readonly MonitoringViewRoles = MonitoringViewRoles;
}
